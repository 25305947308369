import { render, staticRenderFns } from "./whNoSelect.vue?vue&type=template&id=066de406&scoped=true&"
import script from "./whNoSelect.vue?vue&type=script&lang=js&"
export * from "./whNoSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "066de406",
  null
  
)

export default component.exports