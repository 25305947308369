<template>
	<div class="mainTem">
		<div class="buyerHeader"><el-page-header @back="goBack" :content="$t('i18nn_e5462a1be765de05')"></el-page-header></div>
		<div style="padding:20px; background-color: #FFFFFF;">
			<span>{{ $t('i18nn_f566f50ef680920c') }}</span>
			<el-radio-group v-model="orderType" :disabled="!hasEdit" size="medium" @change="changeOrderType">
				<!-- <el-radio-button v-for="item in selectOption.wh_work_order_type" :key="item.code" :label="item.code">{{ $Utils.i18nCodeText(item) }}</el-radio-button> -->
				<!-- 一件代发 -->
				<!-- <el-radio-button :label="'40'">{{$t('Storage.nav.A_undertakes_to')}}</el-radio-button> -->
				<!-- 自提 -->
				<!-- <el-radio-button :label="'30'">{{ $t('i18nn_6b1945319eb18d5f') }}</el-radio-button> -->
				<!-- 退货管理 -->
				<!-- <el-radio-button :label="'50'">{{ $t('i18nn_199f5bded0b23976') }}</el-radio-button> -->
				<!-- 入库申请 -->
				<!-- <el-radio-button :label="'60'">{{$t('Storage.nav.My_packing_list')}}</el-radio-button> -->
				
				<el-radio-button :label="'20'"><span>{{$t('i18nn_f80dedf7c601059f')}}</span><span>(service)</span></el-radio-button>
				<el-radio-button :label="'10'"><span>{{$t('i18nn_429b4e0f15fd551d')}}</span><span>(question)</span></el-radio-button>
				
				
			</el-radio-group>
		</div>

		<div style="padding:10px; background-color: #FFFFFF;">
			<div v-if="10 == orderType || 20 == orderType">
				<questionTem @submitSuccess="goWorkOrderList"></questionTem>
			</div>
			<!-- <div v-else-if="30 == orderType"><TransshipmentTem @submitSuccess="goBack"></TransshipmentTem></div> -->
			<!-- <div v-else-if="30 == orderType">
					<transferOrderTem @submitSuccess="goWorkOrderList"></transferOrderTem>
			</div> -->
			<!-- <div v-else-if="40 == orderType"><dropShippingTem @submitSuccess="goWorkOrderList"></dropShippingTem></div> -->
			<!-- <div v-else-if="50 == orderType"><ExchangeReturn @submitSuccess="goBack"></ExchangeReturn></div> -->
			<!-- <div v-else-if="60 == orderType"><PackingWarehousingTem @submitSuccess="goWorkOrderList"></PackingWarehousingTem></div> -->
		</div>
	</div>
</template>
<script>
import {
		getDicData
	} from '@/axios/common.js';
import questionTem from '@/components/StorageCenter/workOrder/workOrderTem/questionTem.vue';
// import TransshipmentTem from '@/components/StorageCenter/workOrder/workOrderTem/TransshipmentTem.vue';
// import transferOrderTem from '@/components/StorageCenter/workOrder/workOrderTem/transferOrderTem.vue';
// import dropShippingTem from '@/components/StorageCenter/workOrder/workOrderTem/dropShippingTem.vue';
// import PackingWarehousingTem from '@/components/StorageCenter/workOrder/workOrderTem/PackingWarehousingTem.vue';
// import ExchangeReturn from '@/components/StorageCenter/workOrder/workOrderTem/ExchangeReturn.vue';

export default {

	components: {
		questionTem,
		// TransshipmentTem,
		// transferOrderTem,
		// dropShippingTem,
		// PackingWarehousingTem,
		// ExchangeReturn
	},
	data() {
		return {

			hasEdit: false,
			orderType:"10",
			selectOption: {
				wh_work_order_type: [],
			}
		};
	},

	//创建时
	created() {
		// this.getPageData();
		if(this.$route.query && this.$route.query.orderType){
			this.orderType = this.$route.query.orderType;
		} else {
			// this.orderType = 10;
		}
		this.$forceUpdate();
		// this.getDicData();
		//数据字典
		getDicData(['wh_work_order_type'],
			(data)=>{
				this.selectOption.wh_work_order_type = data['wh_work_order_type'];
		});
		this.initData();
	},
	//编译挂载前
	mounted() {

	},
	methods: {
		initData() {
			if(this.$route.query.id){

			} else {
				this.hasEdit = true;
			}
		},
		goBack(){
			this.$router.go(-1);
		},
		goWorkOrderList(){
			this.$router.push({ name: 'workOrderList' });
		},
		changeOrderType(v){
			console.log(v);
		},

		//查询数据字典
		// getDicData() {
		// 	this.$http
		// 		.put(this.$urlConfig.HyDicQueryList, ['wh_work_order_type'])
		// 		.then(({ data }) => {
		// 			console.log('查询数据字典，请求成功');
		// 			console.log(data);
		// 			if (200 == data.code && data.data) {
		// 				this.selectOption.wh_work_order_type = data.data['wh_work_order_type'];
		// 			} else {
		// 				if (!data.msg) {
		// 					data.msg = this.$t('tips.submitError');
		// 				}
		// 				this.$message.warning(data.msg);
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error);
		// 			console.log('查询数据字典接口，请求失败');
		// 			this.$message.error(this.$t('tips.submitRequestError'));
		// 		});
		// }
	}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
